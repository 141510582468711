
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticator from '@/modules/Auth/services/authenticator';
import { RETURN_ROUTE_LOCAL_STORAGE_KEY } from '@/modules/System/constants';
import localStorageManager from '@/utils/local-storage-manager';
import dateManager from '@/utils/time';

@Component
export default class Login extends Vue {
    private loader = !!localStorageManager.getStorage(RETURN_ROUTE_LOCAL_STORAGE_KEY);

    login() {
        authenticator.startLogin();
    }

    loaderCheck() {
        setTimeout(() => {
            try {
                const returnRouteObject = JSON
                    .parse(localStorageManager.getStorage(RETURN_ROUTE_LOCAL_STORAGE_KEY));

                if (returnRouteObject.expirationDate && dateManager
                    .isBefore(dateManager.getCurrentDateTime(), returnRouteObject.expirationDate)) {
                    this.loaderCheck();
                } else {
                    this.stopLoader();
                }
            } catch {
                this.stopLoader();
            }
        }, 1000);
    }

    stopLoader() {
        this.loader = false;
        localStorageManager.clearStoreState(RETURN_ROUTE_LOCAL_STORAGE_KEY);
    }

    created() {
        this.loaderCheck();
    }
}
